@use "../../colors";

.main-events {
  margin: 30px auto 100px;

  &__city {
    font-family: "Montserrat", sans-serif;
    //font-size: 36px;
    line-height: 44px;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: colors.$primary;
    margin: 0 40px;
  }

  @media screen and (max-width: 460px) {
    padding: 0 15px;
    margin-bottom: 40px;
  }

  &__head {
    display: flex;
    align-items: center;

    @media screen and (max-width: 940px) {
      flex-wrap: wrap;
    }
    h2 {
      margin-bottom: 15px;
      font-family: "Montserrat", sans-serif;
      font-size: 41px;
      line-height: 50px;

      @media screen and (max-width: 940px) {
        width: 100%;
      }
      @media screen and (max-width: 460px) {
        margin-bottom: 30px;
        font-size: 26px;
        line-height: 32px;
      }
    }
  }
  &__search {
    position: relative;
    flex-shrink: 0;
    margin-left: auto;
    width: 362px;
    @media screen and (max-width: 940px) {
      margin-left: 0;
    }

    @media screen and (max-width: 460px) {
      width: auto;
      flex-grow: 1;
    }
    input {
      width: 100%;
      line-height: 36px;
      border: 1px solid #FFFFFF;
      background-color: transparent;
      color: #FFFFFF;
      border-radius: 2px;
      padding: 0 24px 0 10px;
      &:focus {
        outline: none;
        border-color: colors.$primary;
      }
    }
    svg {
      position: absolute;
      top: 8px;
      right: 6px;
      z-index: 2;
      display: block;
      pointer-events: none;
    }
  }
  &__filter {
    display: flex;
    align-items: center;
    //justify-content: center;
    flex-shrink: 0;
    margin-left: 20px;
    width: 38px;
    height: 38px;
    padding: 0;
    background-color: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 2px;
    span{
      color: white;
      margin-bottom: 17px;
      font-size: 14px;
      padding: 2px 5px;
      font-size: 12px;
      line-height: 12px;
      position: absolute;
      background-color: #ff5900;
      border-radius: 12px;
    }
  }
  &__search-mobile {
    display: flex;
  }
  &__row {
    margin-top: 30px;
    display: flex;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      margin-top: 0;
    }

    @media screen and (max-width: 460px) {
    }
  }
}

.main-events-calendar {
  width: 440px;
  flex-shrink: 0;

  @media screen and (max-width: 1440px) {

  }
  @media screen and (max-width: 460px) {
    width: 100%;
  }

  &__head {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @media screen and (max-width: 940px) {
      justify-content: flex-start;
    }
    &--disabled {
      .main-events-calendar__btn{
      cursor: not-allowed;
      }

      span {
        opacity: 0.5;
      }
    }
    .main-events__filter {
      flex-shrink: 0;
      margin-left: auto;
      margin-right: 10px;
      align-self: center;
    }
  }
  &__city {
    font-family: "Montserrat", sans-serif;
    font-size: 36px;
    line-height: 44px;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: colors.$primary;

    @media screen and (max-width: 460px) {
      font-size: 24px;
    }
  }
  &__show-finished {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: colors.$primary;
    border: none;
    &:hover {
      .main-events-calendar__tooltip {
        opacity: 1;
      }
    }
    .main-events-calendar__tooltip {
      opacity: 0;
    }
  }
  &__tooltip{
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 2;
    width: 300px;
    text-align: center;
    padding: 15px;
    border: 1px solid #ffffff;
    text-transform: none;
    pointer-events: none;
    color: #ffffff;
    opacity: 0;
    background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    transition: 0.2s ease-in;
    &:before {
      content: '';
      display: block;
      border: 4px solid #ffffff;
      border-top: none;
      border-left-color: transparent;
      border-right-color: transparent;
      position: absolute;
      top: -5px;
      right: 16px;
    }
  }
}

.main-events-content {
  margin-left: 40px;
  flex-grow: 1;

  @media screen and (max-width: 1440px) {
    margin-left: 30px;
    width: calc(100% - 470px);
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
  &__head {
    font-weight: 500;
    font-size: 20px;
    line-height: 44px;
    display: flex;

    @media screen and (max-width: 940px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 460px) {
      margin-top: 10px;
      line-height: 28px;
      display: block;
    }
  }
  &__title {
    span {
      color: colors.$primary;
    }
  }
  &__wrap {
    position: relative;
    padding-bottom: 0;
    width: 919px;

    @media screen and (max-width: 1440px) {
      width: 100%;
    }

    @media screen and (max-width: 460px) {
      margin-top: 20px;
      height: unset;
      width: 100%;
    }
  }
  &__list {
    display: flex;
    width: 920px;

    @media screen and (max-width: 1440px) {
      width: 100%;
    }

    @media screen and (max-width: 460px) {
      display: block;
      width: 100%;
    }

    .swiper {
      padding-bottom: 60px;
      height: 566px;
      margin-left: 0;
    }
    .swiper-slide {
      width: unset;
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      color: #FFFFFF;
      opacity: 1;
      scale: 0.6;
    }

    .swiper-button-prev,
    .swiper-button-next {
      top: calc(100% - 20px);
      color: colors.$primary;
    }

    .swiper-button-prev {
      left: 390px;
      @media screen and (max-width: 1440px) {
        left: 0;
      }
      @media screen and (max-width: 460px) {
        left: 110px;
      }
    }

    .swiper-button-next {
      right: 390px;
      @media screen and (max-width: 1440px) {
        right: unset;
        left: 60px;
      }
      @media screen and (max-width: 460px) {
        left: unset;
        right: 110px;
      }
    }


  }
  &__col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 506px;

    //&:not(:first-child) {
    //  margin-left: 20px;
    //}
  }
  &__nav {
    margin-top: 6px;
    display: flex;
    justify-content: center;

    button {
      background-color: inherit;
      border: none;
      cursor: pointer;

      &:first-child {
        margin-right: 90px;
        transform: rotate(180deg);
      }

      &:disabled {
        cursor: default;

        svg {
          width: 24px;

          path {
            fill: white;
          }
        }
      }
    }
  }
  &__all {
    position: absolute;
    bottom: 8px;
    right: 0;
    z-index: 3;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    color: colors.$primary;
    transition: 0.3s color;

    @media screen and (max-width: 560px) {
      display: block;
      position: static;
      margin-top: 20px;
    }
    @media screen and (max-width: 460px) {
      display: block;
      bottom: unset;
      right: unset;
      position: relative;
    }

    &:hover {
      color: colors.$primary;
    }
  }
}
