.login-page {
  position: relative;
  min-height: 100vh;
  padding-bottom: 40px;
  &.registration-page {
    @media (max-width: 1200px) {
      min-height: unset;
    }
  }
  &__wrap {
    position: relative;
    z-index: 3;
    //padding: 0;
  }
  &__head {
    display: flex;
    justify-content: center;
    padding-top: 45px;

    @media (max-width: 660px) {
      padding-top: 20px;
    }

    .header__login {
      @media (max-width: 1440px) {
        display: none;
      }
    }
  }
  &__logo {
    img {
      display: block;
      width: 97px;
    }
  }
  &__content {
    margin-top: 45px;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 1440px) {
      justify-content: center;
    }
    @media (max-width: 660px) {
      margin-top: 20px;
    }
  }
  &__bg {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.disable{
  background: rgb(208, 208, 208);
  cursor: not-allowed;
}

.disable:hover{
  background: rgb(208, 208, 208);
}

.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cac9c9a8;
}
.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 4px;
  border: 4px solid #ff5900;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ff5900 transparent transparent transparent;
}
.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}

.password-control {
  position: absolute;
  top: 45px;
  right: 10px;
  display: inline-block;
  width: 20px;
  height: 20px;
  color: #ffffff;
  cursor: pointer;

  @media (max-width: 660px) {
    top: 35px;
  }
}

.password-control:hover {
  color: #ffffff;
}

.page-container {
  padding-top: 100px;
  padding-bottom: 0px;
  min-height: 75vh;
}

iframe{
  padding: 1px;
  position: relative;
  background: linear-gradient(to bottom, white, rgba(0, 0, 0, 0.7));
}

.all-events-calendar{
  position: absolute;
  z-index: 100;
  right: 50px;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background: rgba(30, 30, 30, 0.6);
  backdrop-filter: blur(4px);
  border-radius: 5px;
  border: 1px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  width: 560px;
}

.card-info{
  &_events{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 220px;
    .LinesEllipsis  {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    @media (max-width: 460px) {
      grid-template-columns: 1fr;
    }
  }
}

.event-images__list{
  width: unset;
  @media (max-width: 460px) {
    width: 100%;
  }
  &.main-events-content__list .swiper{
    padding-top: 50px;
    padding-bottom: 0;
    @media (max-width: 460px) {
      height: 270px;
    }
  }
  &.main-events-content__list .swiper-button-prev{
    top:20px;
    left: 80vw;
    @media (max-width: 460px) {
      display: none;
    }
  }
  &.main-events-content__list .swiper-button-next{
    top:20px;
    right: 0;
    @media (max-width: 460px) {
      display: none;
    }
}
  .swiper-slide{
    display: flex;
    @media (max-width: 460px) {
      display: block;
      width: 100%;
      height: auto;
      position: relative;
    }
    img{
      @media (max-width: 460px) {
        width: 100%;
        height: auto;
      }
    }
    &.swiper{
      overflow: auto;
      &-slide{
        width: unset!important;
        height: 330px;
        @media (max-width: 460px) {
          width: 100%!important;
        }
        &-active{
          height: 500px;
        }
      }
    }
  }
}

.event-same_events__list{
  display: block;
  width: 100%;
  @media (max-width: 460px) {
    width: 100%;
  }
  &.main-events-content__list .swiper{
    padding-top: 50px;
    padding-bottom: 0;
    @media (max-width: 460px) {
      height: 450px;
    }
  }
  &.main-events-content__list .swiper-button-prev{
    top:20px;
    left: 80vw;
    @media (max-width: 460px) {
      display: none;
    }
  }
  &.main-events-content__list .swiper-button-next{
    top:20px;
    right: 0;
    @media (max-width: 460px) {
      display: none;
    }
}
  .swiper-slide{
    @media (max-width: 460px) {
      display: block;
      width: 100%;
      height: auto;
      position: relative;
    }
    img{
      @media (max-width: 460px) {
        width: 100%;
        height: auto;
      }
    }
    &.swiper{
      overflow: auto;
      &-slide{
        @media (max-width: 460px) {
          width: 100%!important;
        }
      }
    }
  }
}

.card-info-top{
  display: flex;
  position: relative;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  @media (max-width: 460px) {
    flex-direction: column;
  }
  &_members {
    margin-left: 20px;
    @media (max-width: 1400px) {
      width: calc(50% - 10px);
    }
    @media (max-width: 1200px) {
      width: 100%;
      margin: 30px 0 0;
    }
    @media (max-width: 740px) {
      margin: 0 0 0 20px;
      width: calc(50% - 10px);
    }
    @media (max-width: 480px) {
      width: 100%;
      margin-left: 0;
      margin-top: 40px;
    }
    p {
      margin-bottom: 0;
    }
    &_images{
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin-top: 20px;

      @media (max-width: 460px) {
        padding-top: 0;
      }
      &_link {
        position: relative;
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 0 20px 20px 0;
        &-tooltip {
          position: absolute;
          bottom: calc(100% + 5px);
          left: 0;
          z-index: 10;
          text-align: center;
          padding: 15px;
          border: 1px solid #ffffff;
          text-transform: none;
          pointer-events: none;
          color: #FFFFFF;
          opacity: 0;
          background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
          box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
          backdrop-filter: blur(20px);
          border-radius: 10px;
          transition: 0.2s ease-in;

          &:before {
            content: '';
            display: block;
            border: 4px solid #ffffff;
            border-bottom: none;
            border-left-color: transparent;
            border-right-color: transparent;
            position: absolute;
            left: 30px;
            bottom: -5px;
          }
        }
        &:hover {
          .card-info-top_members_images_link-tooltip {
            opacity: 1;
          }
        }

      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
      &-empty{
        opacity: 0.2;
        background: white;
        padding: 20px;
      }
    }
    &:hover {
      .event-members__tooltip {
        opacity: 1!important;
      }
    }
    .show-all {
      font-size: 14px;
      line-height: 18px;
      img {
        scale: 0.8;
      }
    }
  }
  &_users{
    width: 690px;
    flex-shrink: 0;
    display: flex;
    margin-top: 20px;

    @media screen and (max-width: 1400px) {
      width: 100%;
    }

    @media (max-width: 1200px) {
      flex-direction: column;
      margin-left: 40px;
      width: unset;
      flex-grow: 1;
    }
    @media (max-width: 740px) {
      margin: 30px 0 0;
      flex-direction: row;
      width: 100%;
    }
    @media (max-width: 480px) {
      width: 100%;
      flex-direction: column;
    }
    p{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #888888;
    }
  }
  &_owner {
    &_img {
      border-bottom: 1px solid #888888;
      padding: 20px 0;
      margin: 20px 0;
      a {
        display: block;
        width: 340px;
        height: 340px;
        border-radius: 50%;
        @media (max-width: 460px) {
          width: 71px;
          height: 71px;
        }
      }
      img {
        width: 340px;
        height: 340px;
        object-fit: cover;
        border-radius: 50%;
        @media (max-width: 460px) {
          width: 71px;
          height: 71px;
        }
      }
      &-unset img{
        opacity: 0.2;
        background: white;
        padding: 20px;
      }
    }

  }
  &_creator {
    display: flex;
    flex-wrap: wrap;
    width: 40vw;
    @media (max-width: 1200px) {
      flex-wrap: wrap;
      width: 100%;
    }
    @media (max-width: 480px) {
      flex-direction: column;
      width: 100%;
    }
    .btn {
      margin-right: 10px;
      width: auto;
      white-space: nowrap;
      @media (max-width: 740px) {
        margin-bottom: 10px;
      }
      @media (max-width: 480px) {
        margin: 0 0 25px;
        height: 50px;
        width: 100%;
      }
    }
    a.btn {
      height: 40px;
      font-size: 16px;
      line-height: 39px;
      text-transform: none;
      border: none;
      border-radius: 59px;
      text-decoration: none;
      @media (max-width: 480px) {
        height: 50px;
        width: 100%;
      }
      &:hover {
        color: #ffffff;
      }
    }
  }
  &_desc{
    margin-left: 40px;
    width: calc(50% - 20px);

    @media (max-width: 1200px) {
      margin: 30px 0 0;
      width: 100%;
    }
    p {
      white-space: pre-wrap;
      a {
        color: #ff5900;
      }
    }
    &-options{
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 0!important;
      padding-bottom: 5px!important;
      span{
        color: white;
        @media (max-width: 460px) {
          display: flex;
        }
      }
      img{
        margin-right: 10px;
        height: 24px;
        width: 24px;
      }
      .event-adress__tooltip {
        bottom: unset;
        top: 54px;
      }
      &:hover {
        .event-adress__tooltip {
          opacity: 1!important;
        }
      }
      @media (max-width: 460px) {
        grid-template-columns: 1.5fr 1fr;
      }
    }
    &-reg{
      display: grid;
      grid-template-columns: 1fr 1fr;
      @media (max-width: 740px) {
        //grid-template-columns: 1fr;
        display: flex;
        flex-wrap: wrap;
      }
      span{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #FF5900;
      }
      button{
        width: 200px;
        height: 40px;
        font-size: 16px;
        line-height: 39px;
        text-transform: none;
        border: none;
        border-radius: 59px;
      }
      .ecsist{
        width: 200px;
        height: 40px;
        margin-bottom: 96px;
        margin-top: 5px;
        font-size: 16px;
        line-height: 39px;
        text-transform: none;
        border-radius: 59px;
        border: 1px solid #ff5900;
        cursor: pointer;
        text-align: center;
      }
      p{
        border: none;
        padding-top: 20px;
        margin: 0!important;

      }
    }
  }
  @media (max-width: 460px) {
    grid-template-columns: 1fr;
  }
}

.travel-container{
  .card-info-top_desc p {
    border-bottom: none;
    padding: 0px 0;
  }
  .card-info-top_owner_img {
    border-bottom: none;
    padding: 20px 0;
    margin: 20px 0;
  }
  .card-info-top_owner_img img {
    width: 175px;
    height: 175px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.card-info-top_desc-duration{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #888888;
}
.map-title{
  border-bottom: 1px solid #A5A5A5;
  padding: 20px 0;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 41px;
  line-height: 50px;
  color: #FFFFFF;
}
.event-adress{
  &__tooltip {
    position: absolute;
    right: 0;
    top: 45%;
    z-index: 10;
    transform: translateY(-50%);
    text-align: center;
    padding: 15px;
    border: 1px solid #ffffff;
    text-transform: none;
    pointer-events: none;
    color: #FFFFFF;
    opacity: 0;
    background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    transition: 0.2s ease-in;

    &:before {
      content: '';
      display: block;
      border: 4px solid #ffffff;
      border-top: none;
      border-left-color: transparent;
      border-right-color: transparent;
      position: absolute;
      top: -5px;
      left: calc(50% - 4px);
    }
  }
}

.event-members{
  &__tooltip {
    position: absolute;
    //left: 0;
    top: 50%;
    z-index: 10;
    transform: translateY(-50%);
    text-align: center;
    padding: 15px;
    border: 1px solid #ffffff;
    text-transform: none;
    pointer-events: none;
    color: #FFFFFF;
    opacity: 0;
    background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    transition: 0.2s ease-in;

    &:before {
      content: '';
      display: block;
      border: 4px solid #ffffff;
      border-top: none;
      border-left-color: transparent;
      border-right-color: transparent;
      position: absolute;
      top: -5px;
      left: calc(50% - 4px);
    }
  }
}
.hidden{
  color: hsla(0,0%,0%,0)!important;
  text-shadow: white 0 0 5px;
  transition: text-shadow 1s cubic-bezier(0, 1, 0, 1);
  position: relative;
  filter: blur(10px);
}

.show-all{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FF5900;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ant-modal-footer{
  display: none;
}

.anticon svg {
  display: inline-block;
  fill: white;
}

.users-row{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap:20px;
  &__head{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    opacity: 0.8;
  }
  @media screen and (max-width: 460px) {
    grid-template-columns: 1fr 1fr 2fr;
  }
  div{
    margin: 15px 0;
    display: flex;
    align-items: center;
  }
  a{
    color: white;
    text-decoration: none;
    font-weight: 500;
  }
  p{
    margin-bottom: 0px;
  }
  img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .empty{
    opacity: 0.2;
    background: white;
    padding: 2px;
    }
  .option-icon{
    height: 20px;
    position: relative;
    display: flex;
    align-items: center;
  }
}

.users-top{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap:20px;
  .active{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #FF5900;
  }
  &__change{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
  }
  @media (max-width: 460px) {
    display: block;;
  }
}

.users-wait{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  p{
    margin-right: 10px;
  }
  @media (max-width: 460px) {
    justify-content: flex-start;
  }
}

.team-options{
  &:hover {
    .team-options__nav{
      opacity: 1;
      pointer-events: auto;
    }
  }
  &__nav{
    position: absolute;
    right: 230px;
    background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    border-radius: 10px;
    border: 1px solid #FFFFFF;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s linear;
    display: block!important;
    padding: 10px 0;
  }

}

.error-message{
  color: #ff5900;
  font-size: 12px;
}

.select__options{
    scrollbar-width: thin;
    scrollbar-color: #FF5900 #201e1e;

  /* Работает в Chrome, Edge и Safari */
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #201e1e;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #FF5900;
    border-radius: 20px;
    border: 3px solid #FF5900;
    }
  }

.moreBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 19px;
  gap: 10px;
  width: 239px;
  height: 50px;
  background: #FF5900;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  color: white;
  text-transform: uppercase;
  /* text-align: center; */
  margin: auto;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background: rgba(30, 30, 30, 0.8);
  backdrop-filter: blur(4px);
  border-radius: 5px;
  border: 1px solid #ffffff;
  color: #ffffff;
  width: 560px;

  @media (max-width: 660px) {
    padding: 24px 15px;
    width: 360px;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
  &__title {
    font-weight: 500;
    font-size: 41px;
    line-height: 50px;
    text-align: center;

    @media (max-width: 660px) {
      font-size: 26px;
      line-height: 32px;
    }
  }
  &__subtitle {
    width: 360px;
    margin-top: 30px;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-align: center;

    @media (max-width: 660px) {
      width: 100%;
      margin-top: 15px;
      font-size: 16px;
      padding: 0 20px 5px;
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 34px 0 0;
    @media (max-width: 660px) {
      margin-top: 25px;
    }
  }
  &__or {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin: 13px 0;
    @media (max-width: 460px) {
    }
  }
  &__item {
    position: relative;
    width: 100%;
    margin: 25px 0 0;
    @media (max-width: 660px) {
      margin-top: 20px;
    }
    span {
      display: block;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      @media (max-width: 660px) {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 17px;
      }
    }
    input {
      display: block;
      width: 100%;
      height: 54px;
      padding: 0 15px;
      background-color: transparent;
      border: 1px solid #c3c3c3;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;

      @media (max-width: 660px) {
        height: 40px;
        font-weight: 400;
      }
      &:-webkit-autofill {
        background: transparent;
      }
      &:focus {
        border-color: #ffffff;
        outline: none;
      }
      &::placeholder {
        color: #c3c3c3;
      }
      &.error_input{
        border: 1px solid #ff5900;
      }
      &:focus {
        border-color: #ffffff;
        outline: none;
      }
    }
    &--error {
      input {
        border-color: red;
      }
    }
  }
  &__error {
    position: absolute;
    bottom: -16px;
    font-size: 12px;
    line-height: 12px;
    color: red;
    text-align: center;
    width: 100%;
    pointer-events: none;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 15px 0 0;

    @media (max-width: 660px) {
      font-size: 12px;
      line-height: 17px;
    }
  }
  &__remember {
    display: flex;
    align-items: center;

    input {
      margin-right: 8px;
    }
  }
  &__restore {
    cursor: pointer;
    transition: 0.2s linear;
    &:hover {
      color: #ff5900;
    }
  }
  &__register {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 25px 0 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    @media (max-width: 660px) {
      font-size: 12px;
      line-height: 17px;
    }

    a {
      color: inherit;
    }
  }
  &__cancel {
    margin-top: 20px;
  }
}
