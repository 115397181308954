.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 170px;

  @media screen and (max-width: 1440px) {
    height: 150px;
  }

  @media screen and (max-width: 1000px) {
    height: 100px;
  }

  //@media screen and (max-width: 700px) {
  //  height: 140px;
  //}

  @media screen and (max-width: 700px) {
    height: unset;
    background: url("../../components/Res/logoT13_footer.svg") no-repeat center bottom;
  }

  &__wrap {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 80px;
    overflow: hidden;
    justify-content: space-between;

    @media screen and (max-width: 1440px) {
      padding-top: 35px;
      padding-bottom: 65px;
    }

    @media screen and (max-width: 1000px) {
      padding-top: 25px;
      padding-bottom: 40px;
    }

    //@media screen and (max-width: 700px) {
    //  padding-top: 40px;
    //
    //}

    @media screen and (max-width: 700px) {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 80px;
      padding-bottom: 45px;
    }
  }

  &__col {
    margin: 0 auto;
    font-size: 14px;
    max-width: 300px;
    p {
      margin: 0;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    a {
      color: #ffffff;
      text-decoration: none;
      text-align: center;
      margin-left: 20px;
    }
    a:hover, a:hover svg {
      color: #ff5900;
      fill: #ff5900;
    }
  }

  &__row {
    margin-left: 50px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;

    //@media screen and (max-width: 700px) {
    //  margin-top: 20px;
    //  margin-left: 0;
    //}

    @media screen and (max-width: 700px) {
      height: 26px;
      margin-top: 35px;
      margin-left: 0;
    }

    a {
      margin-right: 20px;

      @media screen and (max-width: 700px) {
        margin-right: 25px;
      }

      &:last-child {
        margin-left: 28px;
        margin-right: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        text-decoration: none;
        color: #ffffff;

        @media screen and (max-width: 700px) {
          margin-left: auto;
        }
      }
    }
  }
  &__logo {
    img {
      margin-right: 50px;
      display: block;
      width: 145px;

      @media screen and (max-width: 1000px) {
        width: unset;
        margin-right: 0;
      }

      @media screen and (max-width: 460px) {
      }
    }
  }
  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    height: 200px;
    width: auto;
    pointer-events: none;

    @media screen and (max-width: 1440px) {
      right: 50px;
      top: unset;
      bottom: -30px;
      height: 180px;
    }

    @media screen and (max-width: 1000px) {
      right: 30px;
      height: 130px;
    }

    @media screen and (max-width: 700px) {
      display: none;
    }
  }
}

