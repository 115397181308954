@use "../../colors";

.modal-event-to-travel {
  &__wrap {
  }
  &__title {
    font-size: 24px;
  }
  &__form {
    margin-top: 20px;
  }
  &__item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    span {
      display: block;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
    input {
      display: block;
      width: 100%;
      line-height: 46px;
      border: 1px solid #FFFFFF;
      background-color: transparent;
      color: #FFFFFF;
      border-radius: 4px;
      padding: 0 10px;
      transition: 0.2s linear;
      &:focus {
        outline: none;
        border-color: colors.$primary;
      }
    }
    textarea {
      display: block;
      width: 100%;
      height: 250px;
      resize: none;
      border: 1px solid #ffffff;
      background-color: transparent;
      color: #FFFFFF;
      border-radius: 4px;
      padding: 5px 10px;
      transition: 0.2s linear;
      &:focus {
        outline: none;
        border-color: colors.$primary;
      }
    }
  }
  &__errors {
    padding: 10px 0;
    color: red;

    p {
      margin: 0;
    }
  }
  &__footer {
    display: flex;
    //margin-top: 20px;

    .btn {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
  .modal-event-raw{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
  .modal-event-head {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    opacity: 0.8;
  }
  .modal-event-raw__buy{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    align-items: center;
  }
  .modal-event-btns{
    display: flex;
    gap: 20px;
  }
}

.ctrl {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #D5DCE6;
  background-color: #fff;
  border-radius: 5px;
  font-size: 20px;
  height: 32px;
  margin: 5px;
}
.ctrl__counter {
  position: relative;
  width: 50px;
  color: #333C48;
  text-align: center;
  overflow: hidden;
}
.ctrl__counter.is-input .ctrl__counter-num {
  visability: hidden;
  opacity: 0;
  -webkit-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
}
.ctrl__counter.is-input .ctrl__counter-input {
  visability: visible;
  opacity: 1;
  -webkit-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
}
.ctrl__counter-input {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 2;
  box-shadow: none;
  outline: none;
  border: none;
  color: #333C48;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  visability: hidden;
  opacity: 0;
  -webkit-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
}

.ctrl__button {
  text-align: center;
  color: #fff;
  cursor: pointer;
  background-color: #8498a7;
  -webkit-transition: background-color 100ms ease-in;
  transition: background-color 100ms ease-in;
  width: 20px;
}
.ctrl__button:hover {
  background-color: #90a2b0;
  -webkit-transition: background-color 100ms ease-in;
  transition: background-color 100ms ease-in;
}
.ctrl__button:active {
  background-color: #778996;
  -webkit-transition: background-color 100ms ease-in;
  transition: background-color 100ms ease-in;
}
.ctrl__button--decrement { border-radius: 5px 0 0 5px; }
.ctrl__button--increment { border-radius: 0 5px 5px 0; }

.ctrl__counter-num {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 30px;
  visability: visible;
  opacity: 1;
  -webkit-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
}
.ctrl__counter-num.is-increment-hide {
  opacity: 0;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  -webkit-animation: increment-prev 100ms ease-in;
  animation: increment-prev 100ms ease-in;
}
.ctrl__counter-num.is-increment-visible {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-animation: increment-next 100ms ease-out;
  animation: increment-next 100ms ease-out;
}
.ctrl__counter-num.is-decrement-hide {
  opacity: 0;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-animation: decrement-prev 100ms ease-in;
  animation: decrement-prev 100ms ease-in;
}
.ctrl__counter-num.is-decrement-visible {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-animation: decrement-next 100ms ease-out;
  animation: decrement-next 100ms ease-out;
}
@-webkit-keyframes 
decrement-prev {  from {
 opacity: 1;
 -webkit-transform: translateY(0);
 transform: translateY(0);
}
}
@keyframes 
decrement-prev {  from {
 opacity: 1;
 -webkit-transform: translateY(0);
 transform: translateY(0);
}
}
@-webkit-keyframes 
decrement-next {  from {
 opacity: 0;
 -webkit-transform: translateY(-50px);
 transform: translateY(-50px);
}
}
@keyframes 
decrement-next {  from {
 opacity: 0;
 -webkit-transform: translateY(-50px);
 transform: translateY(-50px);
}
}
@-webkit-keyframes 
increment-prev {  from {
 opacity: 1;
 -webkit-transform: translateY(0);
 transform: translateY(0);
}
}
@keyframes 
increment-prev {  from {
 opacity: 1;
 -webkit-transform: translateY(0);
 transform: translateY(0);
}
}
@-webkit-keyframes 
increment-next {  from {
 opacity: 0;
 -webkit-transform: translateY(50px);
 transform: translateY(50px);
}
}
@keyframes 
increment-next {  from {
 opacity: 0;
 -webkit-transform: translateY(50px);
 transform: translateY(50px);
}
}
